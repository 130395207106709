import { Body } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { FooterApps, FooterSocials } from "@app/shared/types/website-pages"

import { footerExternal, footerExternalApp, icons, footerStoreLogo, desktopOnly } from "./styles.css"

export const AppSocial: FC = () => {
  // const { lang, t } = useTranslation("footer")
  const { t } = useTranslation("footer")
  const apps = t<FooterApps>("footer:apps", {}, { returnObjects: true })
  const socials = t<FooterSocials>("footer:socials", {}, { returnObjects: true })

  return (
    <div className={footerExternal}>
      <div className={footerExternalApp}>
        <span className={desktopOnly}>
          <Body noMargin strong>
            {apps?.title && apps.title}
          </Body>
        </span>
        <div className={icons}>
          {apps.elements
            .filter((app) => app.img !== "")
            .map(({ img, url }, index) => (
              <a key={index} href={url} rel="noreferrer" target="_blank">
                <MyImage
                  alt={`${img.replaceAll("-", " ")} logo`}
                  className={footerStoreLogo}
                  height={42}
                  src={`/images/icons/apps/${img}.webp`}
                  width={136}
                />
              </a>
            ))}

          {/* {lang === "fr" && (
            <Typography variant="description">
              <a href="https://info.casavo.com/hubfs/FR%20files/FR%20PDFs/Guide%20utilisation%20app%20Casavo.pdf">
                Lisez le guide : utiliser l'app Casavo
              </a>
            </Typography>
          )} */}
        </div>
      </div>

      <div className={footerExternalApp}>
        <span className={desktopOnly}>
          <Body noMargin strong>
            {socials?.title && socials.title}
          </Body>
        </span>

        <div className={icons}>
          {socials.elements
            .filter((social) => social.img !== "" && social.url !== "")
            .map(({ img, url }, index) => (
              <a key={index} aria-label="social icon" href={url} rel="noreferrer" target="_blank">
                <MyImage alt={`${img} logo`} height="24" src={`/images/icons/social/${img}.svg`} width="24" />
              </a>
            ))}
        </div>
      </div>
    </div>
  )
}
