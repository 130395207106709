import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fdesign-lib%2Fstyle-theme%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA43STW%2FTQBAG4Du%2F4pX2AhKtPPu9w8mO7b%2BBnH6kLW1tQgoGxH9Ha8RhZ3NIrFweeTQz75iP83zC73fA1dVncnGd5ueGoQY%2F7IbuU%2BHEUKnrqGtK1wzlKIRgSzcM1XjTm1S6ze4M6aF0x1Dj9ivdZ3f5KT3kOWN%2BSo8M1dve9qb0xFCd6UynS58YKphggvB93ks77aj0G4ainnoS%2BdzmefQY5fx3eR4%2FOun3DGWMtU7kdsj7%2BtGPou%2FDv3zcIPJ83Lz3rcjnafMuerHXl81TIjHP83b32NKu9JfszlvnS39lKGvbthc5zDmfMeqd%2BE4Whmptn1Jb%2BleG8mHXuFD6kWGaZS3xG8NqiSeGjRLfGOQlfmdQ9eaPc2%2BuDLISfzJ01f0XgyqkhtHALyu0XVY0OB7203tt9Mf%2F%2F%2BY6fBA1lGv0siJeXKJzSe6QN7iwxuQaWlZcPplliGuSOxc6eYavYqPAiNUpKTJSlTslxvR2moVOvAUpdH%2FucnTD0HW3W0aNd4x6h%2FuzBz0wTK0PjHqsR4atez0xtvo%2FfwEYop3XdQUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2Flayout%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WU3W6jMBCF7%2FMUc7NSIsUIN%2FzVudk3qQweEStge41Laau8e2VAGzCJVntnxt8cznjGjt4otZXt%2Bxi%2BdwAtt7VUxGnDgMaZGc672y76y0RKO9I5WV0%2FN3gcoJ1sTYPkglyg3dBpEmjTBcIgNgPwd6f94jzGB%2FIhhbt4W%2FEvHzK6k05qxcBiw53s0Ue%2FiFQCBwZ0pf4yqpe8utZWvytBKt1oy8DWJd%2Bn9AhZcYQiOUIc0YPXuaCsL44BDWyeRqE5W6oLWunOC%2BPe9P2blNo53TLoud0T8kbTYuC6odkhKCqP50IfV%2BVwcIQ3slYMKlQO7cpUsjzeJ7%2Fkh1VKOqYI2ZmGfzIoG11dl3UH2eVoeNWBhVgWnu69inRq1mLPokHuGCg9L4P9Tn4hg0r3vsj%2FbHM%2BGdGDV5Gq9jrKoXKk1MOjNhkuxMjFQMNpL2YxK9BOQ6u0whXyukGcNn5mng3WIpfPTbsPNs3jwEK5YeIiZKqQec1CRGxkkiRkcGTW7f8oCnXKT39G479bFJJDV1lEBVwJ2LdS3Y29JGY4jCLR%2Bk1ZX%2FyimAb9tgIfvBbP0v7lJM%2BKjZF0Vnwy3dN1vO1uP%2B%2BZzxYOBQAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var bottomSpace = '_11rcrvve';
export var layoutContent = '_11rcrvv0';
export var lineBlock = '_11rcrvv1';
export var lineBlockHr = '_11rcrvv2';
export var pageSectionHeader = '_11rcrvv3';
export var pageSectionHeaderH2 = '_11rcrvv4';
export var sectionBlock = '_11rcrvv6';
export var sectionBlockContent = _7a468({defaultClassName:'_11rcrvv7',variantClassNames:{separator:{false:'_11rcrvv8',true:'_11rcrvv9'},wrapper:{lg:'_11rcrvva',md:'_11rcrvvb',sm:'_11rcrvvc',xl:'_11rcrvvd'}},defaultVariants:{},compoundVariants:[]});
export var spacer = '_11rcrvv5';